import { queryClient } from "providers/ReactQueryProvider";
import { useMutation } from "@tanstack/react-query";

import http from "./config/https";

export type RemoveMemberSuccessResponseData = {};

const removeMember = (memberDataEmail: string) =>
  http.postGO(`permission/account_role/RevokeERAdmin?email=` + encodeURIComponent(memberDataEmail), {});

export const useRemoveERMember = () => {
  return useMutation({
    mutationFn: removeMember,
    onSuccess: () => {
      queryClient.removeQueries(["engage_rocket_admins"]);
    },
  });
};
