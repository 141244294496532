import styled from "styled-components";

const AccountActivationError = ({ errorType = "expired" }) => {
  const errorConfig = {
    revoked: {
      iconSrc: "/assets/activation-revoked.png",
      header: "Oops, your invite has been revoked.",
      description: "You can reach out to your administrator for more information."
    },
    expired: {
      iconSrc: "/assets/activation-expired.png",
      header: "Your invite has expired.",
      description: "Invitations to your workspace is only valid for 7 days. You will need your organisation to send you a new invite again."
    }
  };

  const { iconSrc, header, description } = errorConfig[errorType];

  return (
    <Container>
      <ContentWrapper>
        <IconWrapper>
          <Icon src={iconSrc} alt={`${errorType} icon`} />
        </IconWrapper>
        <Header>{header}</Header>
        <Description>{description}</Description>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f3f4f6;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 80vw;
  padding: 4rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  width: 70%;
`;

const Header = styled.h1`
  font-size: 32px
  font-weight: 500;
  color: #3E4450;
  text-align: center;
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  color: #3E4450;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  max-width: 50%;
  margin-top: 10px
`;

export default AccountActivationError;