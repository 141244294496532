import { AxiosResponse } from "axios";
import httpGO from "./config/https";

class UnexpectedApiError extends Error {
  constructor(response: AxiosResponse<any, any>) {
    super();
  }
}

export const RemoveTemplate = async (templateId: number) => {
  const response = await httpGO.removeGO(
    "/template/templ_template_questionary/" + templateId,
    {}
  );
  if ([200, 201].includes(response.status)) {
    return response.data;
  }
  throw new UnexpectedApiError(response);
};
