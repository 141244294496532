// Usage:
// labelPosition: "Left" or "Right"

import { Field, ErrorMessage } from "formik";

import styled from "styled-components";

export const Checkbox = (props: any) => {
  const { labelPosition = "left", ...restOfProps } = props;

  return (
    <CheckBoxWrapper>
      <CheckBoxContent className={`${props.name}-checkbox-wrapper`}>
        <StyledLabel>
          {props.label !== "" && labelPosition === "left" && (
            <span>{props.label}</span>
          )}
          <StyledField type="checkbox" {...restOfProps} />
          {props.label !== "" && labelPosition === "right" && (
            <span>{props.label}</span>
          )}
        </StyledLabel>
        <ErrorMessage name={props.name} component="div" />
      </CheckBoxContent>
    </CheckBoxWrapper>
  );
};

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CheckBoxContent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLabel = styled.label`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  display: flex;
  cursor: pointer;
`;

const StyledField = styled(Field)`
  height: 16px;
  width: 16px;
  outline: none;
  background: var(--colorNeutralBackground1);
  line-height: 20px;
  margin: 0 8px 0 0;
  cursor: pointer;
`;
