import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const UnauthenticatedWrapper: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('ERAccessToken');
    if (token) {
      navigate('/');
    }
  }, [navigate]);

  return <Outlet />;
};

export default UnauthenticatedWrapper;
