import LoginScreen from "../../features/auth/LoginScreen";
import { FC } from "react";

type LoginPageProps = {};

const LoginPage: FC<LoginPageProps> = () => {
  return <LoginScreen />;
};

export default LoginPage;
