import { Input } from "@mui/material";
import { Form, Formik } from "formik";
import { revokeAdmin } from "api/useRevokeAdmin";
import { TWorkspaceAdmin } from "../../types/workspace";
import { FC, ReactElement, useEffect, useState } from "react";

import * as Yup from "yup";
import styled from "styled-components";
import Button from "components/button/Button";
import CustomModal from "components/modal/Modal";

type ModalCreateMemberProps = {
  open: boolean;
  hide: (state: boolean) => void;
  onSave: () => void;
  admin: TWorkspaceAdmin | null;
  id?: string;
};

const schema = Yup.object().shape({
  revoke: Yup.string()
    .required("REVOKE is required")
    .matches(/^REVOKE$/, "Is not in correct format"),
});

const ModalRevokeAdmin: FC<ModalCreateMemberProps> = (props): ReactElement => {
  const [revokeValue, setRevoke] = useState("");
  useEffect(() => {
    setRevoke("")
  }, [props.open])

  const submitForm = async () => {
    revokeAdmin({ workspaceId: props.admin.workspace_id, accountRoleId: props.admin.account_role_id, entityId: props.admin.entity_id, email: props.admin.email })
      .then(x => {
        props.hide(false);
        props.onSave()
      })
    // try {
    //   if (props.admin) {
    //     await mutateAsync({
    //       workspaceId: props.admin.workspaceID,
    //       adminId: props.admin.userID,
    //     });
    //   } else throw new Error();
    // } catch (err: any) {
    // } finally {
    //   props.hide(false);
    // }
  };

  return (
    <CustomModal open={props.open} hide={props.hide} width="468">
      <StyledModalTitle id={props.id && `${props.id}_Title`}>
        Are you sure you want to revoke admin access for this user?
      </StyledModalTitle>
      <StyledModalContent aria-label="Revoke admin form">
        <StyledModalDescription id={props.id && `${props.id}_Subtitle`}>
          {props.admin?.email} will lose access to all admin features
          immediately. To confirm revoking access, please enter REVOKE below:
        </StyledModalDescription>
        <Formik
          initialValues={{ revoke: "" }}
          validationSchema={schema}
          onSubmit={submitForm}
        >
          {({ isValid, dirty }) => (
            <Form>
              <InputWrapper>
                <StyledInput name="revoke" id={props.id && `${props.id}_Input`} placeholder="REVOKE" value={revokeValue} onChange={(e) => setRevoke(e.target.value)} />
              </InputWrapper>
              <ButtonWrapper>
                <StyledNextButton
                id={props.id && `${props.id}_YesConfirmBtn`}
                  variant="outlined"
                  onClick={() => submitForm()}
                  disabled={revokeValue != "REVOKE"}
                >
                  Yes, confirm
                </StyledNextButton>
                <StyledCancelButton
                  id={props.id && `${props.id}_CanselBtn`}
                  variant="outlined"
                  onClick={() => props.hide(false)}
                >
                  Cancel
                </StyledCancelButton>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalRevokeAdmin;

const StyledModalTitle = styled.span`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
`;

const StyledModalContent = styled.div``;

const InputWrapper = styled.div`
  margin-bottom: 40px;
`;

const StyledInput = styled(Input)`
  width: 100%;

  label {
    margin-top: 24px;
  }

  input {
    font-size: 18px;
    color: var(--colorNeutralForeground1);

    &::placeholder {
      font-size: 18px;
      font-weight: 400;
      color: var(--grayInputPlaceholder);
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledCancelButton = styled(Button)`
  width: 100%;
  max-width: 75px;
  margin-left: 16px;
  height: 36px;
  color: var(--colorNeutralForeground1) !important;
  border-color: var(--colorNeutralForeground1) !important;
`;

const StyledNextButton = styled(Button)`
  width: 100%;
  max-width: 150px;
  height: 36px;
  margin-right: 20px !important;
`;

const StyledModalDescription = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin: 20px 0px 32px 0px;
`;
