import http from "./config/https";

export const confirmResetPassword = (email_code: string, password: string) => {
    return http.postUnauthenticatedGO(
        "/permission/ResetPassword/Confirm",
        {
            email_code,
            password
        },
        {}
    )
}