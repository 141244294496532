import { FC, useEffect, useState } from "react";

import styled from "styled-components";
import Button from "components/button/Button"
import { useCognito } from "CognitoAuthProvider";

const AccountActivationScreen: FC = () => {

  const userCognitoProvider = useCognito();

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState<string | null>(null);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState<string | null>(null);



  const onSetNewPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    userCognitoProvider.setNewPassword(newPassword).then((response) => {
      window.location.href = "/";
    });
  }

  useEffect(() => {
    const PASSWORD_CONDITIONS =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{6,}/;

    setNewPasswordError(null);
    setConfirmNewPasswordError(null);

    if (
      !newPassword ||
      newPassword.length < 8 ||
      !PASSWORD_CONDITIONS.test(newPassword)
    ) {
      setNewPasswordError(
        "Oops, your password does not meet our requirements."
      );
    } else if (confirmNewPassword !== newPassword) {
      setConfirmNewPasswordError("Passwords must match");
    } else if (!confirmNewPassword) {
      setConfirmNewPasswordError(
        "Oops, your password does not meet our requirements."
      );
    } else {
      setNewPasswordError(null);
      setConfirmNewPasswordError(null);
    }
  }, [newPassword, confirmNewPassword]);

  return (
    <ForgotPasswordPageWrapper>
      <PageContainer>
        <CoverImageWrapper>
          <img src="/assets/password-page-sidebar.png" alt="set-password" />
        </CoverImageWrapper>
        <FormContainer id="reset-view">
          <FormContainerContent>
            <form onSubmit={onSetNewPassword} id="change-password-form">
              <FormTitle>Create a password to get started</FormTitle>

              <InputLabel>New password</InputLabel>
              <StyledInput
                type="password"
                id="newPassword"
                name="newPassword"
                placeholder="Enter new password"
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {Boolean(newPasswordError) && (
                <Warning role="alert">{newPasswordError}</Warning>
              )}
              <InputLabel>Confirm new password</InputLabel>
              <StyledInput
                type="password"
                id="confirmNewPassword"
                name="confirmNewPassword"
                placeholder="Enter new password again"
                required
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              {Boolean(confirmNewPasswordError) && (
                <Warning role="alert">{confirmNewPasswordError}</Warning>
              )}
              <ButtonWrapper className="action">
                <SendButton
                  type="submit"
                  variant="contained"
                  disabled={
                    Boolean(confirmNewPasswordError) ||
                    Boolean(newPasswordError)
                  }
                >
                  Save changes
                </SendButton>
              </ButtonWrapper>
            </form>
            <PaswordGuideContainer className="password-guide-container">
              <PasswordGuideTitle>Ensure that your password::</PasswordGuideTitle>
              <p>Is at least 8 characters long and contains one Uppercase and lowercase letter (A, z), one numeric character (0-9) and one special character (!, %, @, #, etc.)</p>
            </PaswordGuideContainer>
            <LogoWrap>
              <Logo src={"/assets/er-logo-light.png"} />
            </LogoWrap>
          </FormContainerContent>
        </FormContainer>
        <div id="completed-view" style={{ display: "none" }}>
          Nice work, you changed your password.
        </div>
      </PageContainer>
      <CopyrightWrap>
        <p>COPYRIGHT © ENGAGEROCKET 2024</p>
        <p>ALL RIGHTS RESERVED</p>
      </CopyrightWrap>
    </ForgotPasswordPageWrapper>
  );
};

export default AccountActivationScreen;

const ForgotPasswordPageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  padding: 20px;
  position: relative;
`;

const CopyrightWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  font-size: 11px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 16px;
  color: #9E9E9E;
`

const PageContainer = styled.div`
  width: 100%;
  height: 698px;
  max-width: 1080px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  background-color: var(--colorNeutralBackground1);
`;

const CoverImageWrapper = styled.div`
  width: 100%;
  max-width: 485px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 20px 20px 0px;
  img {
    width: 276px;
    height: 260px;
  }
`;

const FormContainer = styled.div`
  padding: 0px 80px 0px 80px;
  width: 100%;
  max-width: 595px;
  position: relative;
  display: flex;
`;

const FormContainerContent = styled.div`
  width: 100%;
  max-width: 420px;
  margin: auto 0px;
`;

const LogoWrap = styled.div`
`

const Logo = styled.img`
  width: 184px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const SendButton = styled(Button)`
  width: 100%;
  height: 36px;
`;

const FormTitle = styled.h1`
  font-weight: 500;
  font-family: Roboto;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 40px;
  color: #3E4450;
`

const PasswordGuideTitle = styled.p`
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 0px;
`

const Warning = styled.div`
  color: var(--colorPaletteRedForeground2);
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 27px;
`;

const PaswordGuideContainer = styled.div`
  background: var(--grenBackgroundColor);
  border: 1px solid var(--greenBorderColor);
  margin-top: 24px;
  margin-bottom: 92px;
  padding: 8px 16px;
  p.contains {
    margin-top: 12px;
    margin-bottom: 7px;
  }
  p,
  ul {
    color: var(--colorNeutralForeground1);
    font-family: Roboto;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
  }
  ul {
    margin: 0;
    padding-left: 16px;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 32px;
  box-shadow: inset 0px -1px 0px #cdd3ec;
  width: 100%;
  border: none;
  outline: none;
  background: #ffffff;
  border-radius: 0px;
  color: #323232;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  &:focus {
    box-shadow: inset 0px -1px 0px var(--colorBrandForeground1);
  }
`;

const InputLabel = styled.label`
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #707882;
  margin: 24px 0px 6px 0px;
`;
