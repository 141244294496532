import { NavLink } from "react-router-dom";

import styled from "styled-components";

export const Link = (props: any) => {
  const { activeClassName, customClass = "", ...restOfProps } = props;

  return (
    <StyledNavLink
      className={`${customClass} ${activeClassName}`}
      {...restOfProps}
    >
      {props.text}
    </StyledNavLink>
  );
};

const StyledNavLink = styled(NavLink)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  color: var(--colorBrandForeground1);
  text-decoration: none;
`;
