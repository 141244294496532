import { Field } from "formik";
import { Message as ErrorMessage } from "../error/Message";
import { ComponentPropsWithoutRef } from "react";

import styled from "styled-components";

const customInput = (
  props: ComponentPropsWithoutRef<"input"> & { label: string }
) => (
  <CustomInputWrapper className={` ${props.name}-input-wrapper`}>
    {props.label !== "" && (
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
    )}
    <StyledInput {...props} />
    <ErrorMessage name={props.name} component="div" />
  </CustomInputWrapper>
);

export const Input = (props: any) => (
  <InputWrapper>
    <Field {...props} as={customInput} />
  </InputWrapper>
);

const InputWrapper = styled.div``;

const CustomInputWrapper = styled.div``;

const InputLabel = styled.label`
  display: block;
  font-family: $text-font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
  margin: 24px 0px 6px 0px;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 32px;
  box-shadow: inset 0px -1px 0px var(--colorPaletteBlueBackground1);
  width: 100%;
  border: none;
  outline: none;
  background: var(--colorNeutralBackground1);
  border-radius: 0px;
  color: var(--colorNeutralForeground3);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  ::placeholder {
    color: var(--colorNeutralForeground7);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  &:focus {
    box-shadow: inset 0px -1px 0px var(--colorBrandForeground1);
  }
`;
