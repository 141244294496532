import { createContext, ReactNode, useContext, useMemo } from "react";
import { cognitoService, type CognitoService } from './CognitoService';

interface CognitoProviderProps {
  children: ReactNode;
}

const CognitoContext = createContext<CognitoService | null>(null);

export const CognitoProvider: React.FC<CognitoProviderProps> = ({ children }) => {
  const value = useMemo<CognitoService>(() => ({
    login: cognitoService.login,
    logout: cognitoService.logout,
    refreshToken: cognitoService.refreshToken,
    setNewPassword: cognitoService.setNewPassword,
    getAccessToken: cognitoService.getAccessToken,
    getRefreshToken: cognitoService.getRefreshToken,
    requestNewPassword: cognitoService.requestNewPassword,
    confirmNewPassword: cognitoService.confirmNewPassword,
  }), []);

  return (
    <CognitoContext.Provider value={value}>
      {children}
    </CognitoContext.Provider>
  );
};

export const useCognito = () => {
  const context = useContext(CognitoContext);
  if (!context) {
    throw new Error('useCognito must be used within a CognitoProvider');
  }
  return context;
};
