import { resendResetPassword } from "api/useResendResetPassword";
import Button from "components/button/Button";

import { useState } from "react";
import styled from "styled-components";

const ResetPasswordScreenButton: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [summarizedResult, setSummarizedResult] = useState({
        msg: "",
        isError: false,
    })

    const handleResend = async () => {
        try {
            setLoading(true);
            const params = new URLSearchParams(window.location.search);
            await resendResetPassword(params.get('emailCode') as string);

            setSummarizedResult({
                msg: "A new reset password link has been sent to your email.",
                isError: false,
            })
        } catch (err) {
            setSummarizedResult({
                msg: "Something went wrong and we couldn’t process your request. Please try again. Contact support@engagerocket.co if the problem persists.",
                isError: true,
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Desc $isHaveError={Boolean(summarizedResult.isError)}>
                {summarizedResult.msg}
            </Desc>
            <SendButton disabled={loading} onClick={handleResend} variant="contained">
                Request for a new link
            </SendButton>
        </>
    )
}

export default ResetPasswordScreenButton

const SendButton = styled(Button)`
  width: 100%;
  height: 36px;
`;


const Desc = styled.h2<{ $isHaveError: boolean }>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => (props.$isHaveError ? "#BD0202" : "#209E67")};
`;