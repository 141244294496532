import { ErrorMessage } from "formik";

import styled from "styled-components";

export const Message = (props: any) => (
  <div>
    <StyledErrorMessage name={props.name} component="div" />
  </div>
);

const StyledErrorMessage = styled(ErrorMessage)`
  color: var(--colorPaletteRedForeground2);
  margin-top: 2px;
`;
