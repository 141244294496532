import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { Pagination } from "@mui/material";

import { AdminsTable } from "../admins-table/AdminsTable";
import useSortedData from "../../utils/useSortedData";
import FullPageLoadingIndicator from "../../components/Loader/FullPageLoadingIndicator";
import GetAllWorkspaceOrEntityAdmin from "api/useGetAllAdmin";

const AdminsScreen = (): ReactElement => {
  const [params, setParams] = useState(defaultParams);

  const handleParams = (newParams: { [key: string]: string | undefined }) =>
    setParams((prev) => ({ ...prev, ...newParams }));

  const [isLoadingGetWorkspacesAdmins, setIsLoadingGetWorkspaceAdmins] =
    useState(true);
  const [workspacesAdminsData, setAdminData] = useState(null);

  useEffect(() => {
    GetAllWorkspaceOrEntityAdmin().then((response) => {
      setAdminData({
        admins: response.data.admins,
      });
      setIsLoadingGetWorkspaceAdmins(false);
    });
  }, []);

  const { sortedData, changeSort, sortModel } = useSortedData(
    workspacesAdminsData?.admins,
    { field: null, sort: null },
  );

  return (
    <Container>
      {isLoadingGetWorkspacesAdmins && <FullPageLoadingIndicator />}
      <HeaderContainer>
        <Header id="WorkspaceEntityAdmins_Title" >Workspace & Entity admins</Header>
      </HeaderContainer>
      <TableWrapper>
        <AdminsTable
          list={!isLoadingGetWorkspacesAdmins ? sortedData : []}
		      id="WorkspaceEntityAdmins"
          sort_by={params.sort_by}
          refetch={() => {
            GetAllWorkspaceOrEntityAdmin().then((response) => {
              setAdminData({
                admins: response.data.admins,
              });
              setIsLoadingGetWorkspaceAdmins(false);
            });
          }}
          sort_type={params.sort_type}
          handleParams={handleParams}
          search_by={params.search_by}
          search_value={params.search_value}
          changeSort={changeSort}
          sortModel={sortModel}
        />
        <PaginationWrapper>
          <PaginationText>
          </PaginationText>
          <Pagination
            //@ts-ignore
            activepage={workspacesAdminsData?.activePage}
            itemscountperpage={workspacesAdminsData?.itemsCountPerPage}
            pagerangedisplayed={5}
            totalitemscount={workspacesAdminsData?.totalItemsCount || 10}
            totalpages={workspacesAdminsData?.totalPages}
            showtotalpage={"false"}
          />
        </PaginationWrapper>
      </TableWrapper>
    </Container>
  );
};

export default AdminsScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  background-color: var(--colorNeutralForegroundInverted1);
  padding: 48px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const Header = styled.h1`
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0;
`;

const TableWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const PaginationText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.7384616136550903px;
  text-align: left;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
`;

const defaultParams = {
  activePage: 1,
  itemsCountPerPage: 10,
  totalItemsCount: 0,
  totalPages: 0,
  sort_by: undefined,
  sort_type: undefined,
  search_by: undefined,
  search_value: "",
};
