import { useNavigate } from "react-router-dom";
import Button from "components/button/Button";
import styled from "styled-components";

const NavigateToLoginButton = () => {
    const navigate = useNavigate();
    return (
        <NavigateButton
            onClick={() => {
                navigate("/login");
            }} variant="contained"
        >
            Back to sign in page
        </NavigateButton>
    )
}
export default NavigateToLoginButton;
const NavigateButton = styled(Button)`
  width: 100%;
  height: 36px;
`;