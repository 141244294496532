import http from "./config/https";

export const requestResetPassword = (email: string) => {
    return http.postUnauthenticatedGO(
        "/permission/ResetPassword/Request",
        {
            email,
            platform: "internal"
        },
        {}
    )
}