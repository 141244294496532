import { TRecepient, TRecepientStatus } from "types/survey";
import { makeAutoObservable } from "mobx";
import MainStore from "MainStore";
import { GridSortModel, GridFilterModel } from "@mui/x-data-grid";
import getRecepientsFilter from "api/useGetRecepientsFilter";
import getRecepientsStatus from "api/useGetRecepientsStatus";
import { resetResponse } from "api/useResetResponse";
import { resendInvite } from "api/useResendInvite";

class Store {
  surveyId: number = 0;
  recepientList: TRecepient[] = [];
  recepientStatuses: TRecepientStatus[] = [];

  recepientsTotalCount: number = 0;
  recepientsPageSize: number = 10;
  recepientsPage: number = 0;
  recepientsSortField: string = "";
  recepientsSortType: "asc" | "desc" | null = null;
  recepientsSearchValue: string = "";
  recepientsFilterField: string = "";
  recepientsFilterOperator: string = "";
  recepientsFilterValue: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  clearStore = () => {};

  setData = (field: string, value: any) => {
    this[field] = value;
  };

  doLoad = (surveyId: number) => {
    this.setData("surveyId", surveyId);
    this.getRecepients();
    this.getRecepientStatuses();
  };

  changeSortRecepients = (sort: GridSortModel) => {
    if (sort.length === 0) {
      this.recepientsSortField = "";
      this.recepientsSortType = null;
    } else {
      this.recepientsSortField = sort[0].field;
      this.recepientsSortType = sort[0].sort;
    }
    this.getRecepients();
  };

  changeFilterRecepients = (filter: GridFilterModel) => {
    if (filter.items.length === 0) {
      this.setData("recepientsFilterField", "");
      this.setData("recepientsFilterOperator", "");
      this.setData("recepientsFilterValue", "");
    } else {
      this.setData("recepientsFilterField", filter.items[0].field);
      this.setData("recepientsFilterOperator", filter.items[0].operator);
      if (Array.isArray(filter.items[0].value)) {
        this.setData("recepientsFilterValue", filter.items[0].value.join(", "));
      } else {
        this.setData("recepientsFilterValue", filter.items[0].value);
      }
    }
    this.getRecepients();
  };

  changePaginationRecepients = (page: number, pageSize: number) => {
    this.recepientsPage = page;
    this.recepientsPageSize = pageSize;
    this.getRecepients();
  };

  getRecepients = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getRecepientsFilter(
        this.surveyId,
        this.recepientsSearchValue,
        this.recepientsPage,
        this.recepientsPageSize,
        this.recepientsSortField,
        this.recepientsSortType,
        this.recepientsFilterField,
        this.recepientsFilterOperator,
        this.recepientsFilterValue
      );
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setData("recepientList", response.data.recepients);
        this.setData("recepientsTotalCount", response.data.count);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  resetResponse = async (id: number, preferredName: string) => {
    try {
      MainStore.changeLoader(true);
      const response = await resetResponse(id);
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        MainStore.setSnackbar(
          `Responses from ${preferredName} has been successfully reseted.`
        );
        this.getRecepients();
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  resendInvite = async (employeeId: number, preferredName: string) => {
    try {
      MainStore.changeLoader(true);
      const response = await resendInvite(this.surveyId, [employeeId]);
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        MainStore.setSnackbar(
          `Invite to ${preferredName} has been successfully sent.`
        );
        this.getRecepients();
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getRecepientStatuses = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getRecepientsStatus();
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setData("recepientStatuses", response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
